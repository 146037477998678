import api from "."
import { API_TOKEN } from "./constants"

export function getIccdsData(cpf) {
    const baseurl = "/ver"
    return api.post(baseurl, {
        token: API_TOKEN,
        cpf: cpf
    })
}

export function getInvoicesData(iccid) {
    const baseurl = "/fatura/consulta"
    return api.post(baseurl, {
        token: API_TOKEN,
        iccid: iccid
    })
}